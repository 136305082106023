import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import apiActions from 'api/actions';
import imagePath from 'utils/imagePath';
import defaultProfileImage from 'assets/img/shared/missing.png';
import sessionStore from '../session-store';

export default class UserStore {
  constructor(root) {
    makeObservable(this, {
      data: observable,
      requests: observable,
      add: action,
      addMany: action,
      remove: action,
      all: computed,
      reset: action,
      fetchAll: action,
      refetch: action,
      fetchAllCompleted: computed,
      length: computed
    });

    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const user = new User(data, this.root);
    this.data.set(user.id, user);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const user = new User(data, this.root);
      merge.set(user.id, user);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values()).filter(user => user.active);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('users').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }
}

export class User {
  constructor(data, root) {
    this._root = root;
    this.learning_centers_led = data.learning_centers_led;
    this.active = data.active;
    this.created_at = data.created_at;
    this.default_org_id = data.default_org_id;
    this.display_first_name = data.display_first_name;
    this.display_last_name = data.display_last_name;
    this.email = data.email;
    this.first_name = data.first_name;
    this.id = data.id;
    this.image_file_name = data.image_file_name;
    this.last_name = data.last_name;
    this.machine_name = data.machine_name || `${data.first_name} ${data.last_name}`;
    this.name = data.name || `${data.display_first_name ? data.display_first_name : data.first_name} ${data.display_last_name ? data.display_last_name : data.last_name}`;
    this.public_description = data.public_description;
    this.parent_managed = data.parent_managed;
    this.prefers_2D_interface = data.prefers_2D_interface;
    this.child_image_option_id = data.child_image_option_id;
  }

  authorization(orgId) {
    const authorizations =  _(this.memberships)
      .filter(['organization_id', orgId])
      .map('authorization')
      .value();

    if (_.includes(authorizations, 'org_admin')) {
      return 'admin';
    }

    if (_.includes(authorizations, 'team_leader') || this.teamsLed.length > 0) {
      return 'teamLeader';
    }

    if (_.includes(authorizations, 'user_creator')) {
      return 'user_creator';
    }

    return 'user';
  }

  get imagePath() {
    return this.image_file_name ? imagePath(this, 'users') : defaultProfileImage;
  }

  get memberships() {
    return this._root.MembershipStore.forUser(this.id);
  }

  get organizations() {
    const { OrganizationStore } = this._root;
    return _(this.memberships)
      .map(membership => OrganizationStore.find(membership.organization_id))
      .uniqBy('id')
      .compact()
      .value();
  }

  get trial() {
    return _.find(this.organizations, 'trial');
  }

  get teamPositions() {
    return this._root.TeamPositionStore.forUser(this.id);
  }

  get teams() {
    return _.compact(this.teamPositions.map(position => position.team));
  }

  get executions() {
    return this._root.ActivityExecutionStore.forUser(this.id);
  }

  get activityInstances() {
    return _.compact(this.executions.map(execution => execution.activityInstance));
  }

  get trackInstances() {
    const orgId = _.get(sessionStore, 'organization.id');
    return this._root.TrackInstanceStore.forUser(this.id, orgId);
  }

  get incompleteTrackInstances() {
    return this.trackInstances.filter(ti => ti.track && ti.track.active && !ti.completed);
  }

  get completeTrackInstances() {
    return this.trackInstances.filter(ti => ti.completed);
  }

  get incompleteActivityInstances() {
    return this.activityInstances.filter(instance => instance && !instance.completed_at);
  }

  get completedActivityInstances() {
    return this.activityInstances.filter(instance => {
      const complete = instance && instance.completed;
      if (instance.manually_completed_at) {
        return complete && instance.onlyEvaluationIncomplete;
      }
      return complete;
    });
  }

  get incompleteNonCompetitionActivityInstances() {
    return this.incompleteActivityInstances.filter(instance => !instance.competition_team_id);
  }

  get activityInstancesCompletedThisMonth() {
    const startOfMonth = moment().startOf('month');
    return this.activityInstances.filter(instance => (
      instance &&
      instance.completed_at &&
        moment(instance.completed_at).isAfter(startOfMonth)
    ));
  }

  get activityInstancesCompletedToday() {
    const startOfDay = moment().startOf('day');
    return this.activityInstances.filter(instance => (
      instance &&
      instance.completed_at &&
        moment(instance.completed_at).isAfter(startOfDay)
    ));
  }

  get competitionTeams() {
    return this._root.CompetitionTeamStore.teamsForUser(this.id);
  }

  get competitions() {
    return _.compact(this.competitionTeams.map(team => team.competition));
  }

  get incompleteCompetitions() {
    return this.competitions.filter(competition => !competition.completionDate);
  }

  isUserCreator(organizationId) {
    return this.authorization(organizationId) === "user_creator";
  }

  isTeamLeader(organizationId) {
    return this.teamPositions.some(position => position.team && !position.team.archived && position.isTeamLeader(organizationId)) ||
      this.authorization(organizationId) === "teamLeader";
  }

  isCoach(organizationId) {
    organizationId = parseInt(organizationId, 10);
    return this._root.CoachingStore.coachedForUser(this.id).some(coach => (
      coach.team && coach.team.organization_id === organizationId
    ));
  }

  get usersCoached() {
    return this._root.CoachingStore.coachedForUser(this.id).map(coaching => coaching.coached);
  }

  get teamsLed() {
    return _(this.teamPositions)
      .filter(position => position.position === 'leader')
      .flatMap(position => position.team)
      .compact()
      .filter(team => !team.archived)
      .value();
  }

  get archivedTeamsLed() {
    return _(this.teamPositions)
      .filter(position => position.position === 'leader')
      .flatMap(position => position.team)
      .compact()
      .filter(team => team.archived)
      .value();
  }

  get usersLed() {
    return _(this.teamsLed)
      .flatMap(team => team.users)
      .compact()
      .uniqBy('id')
      .value();
  }

  get userIdsLed() {
    return this.usersLed.map(user => user.id);
  }

  get createdActivityInstances() {
    return this._root.ActivityInstanceStore.createdBy(this.id);
  }

  get createdActivityInstancesCompleted() {
    return this._root.ActivityInstanceStore.completedInstancesCreatedBy(this.id);
  }

  get createdIncompleteNonCompetitionActivityInstances() {
    return this._root.ActivityInstanceStore.incompleteNonCompetitionInstancesCreatedBy(this.id);
  }

  get createdIncompleteCompetitions() {
    return this._root.CompetitionStore.incompleteCompetitionsCreatedBy(this.id);
  }

  get highestRole() {
    const orgId = _.get(sessionStore, 'organization.id');
    const parentId = _.get(sessionStore, 'parent.id');

    if (orgId) {
      if (this.authorization(orgId) === 'admin') return 'admin';
      if (this.isTeamLeader(orgId)) return 'teamLeader';
      if (this.authorization(orgId) === 'user_creator') return 'user_creator';
      return 'user';
    }
    if (parentId) {
      return 'parent';
    }
    return undefined;
  }

  get isParent() {
    const parentId = _.get(sessionStore, 'parent.id');

    return parentId && this._root.ParentStore.find(parentId);
  }

  leadsLearningCenterForOrg(orgId) {
    return (
      this.learning_centers_led &&
      this.learning_centers_led.length > 0 &&
      this.learning_centers_led.filter(lc => lc.organization_id === orgId).length > 0
    );
  }

  learningCenterForOrg(orgId) {
    if (this.learning_centers_led && this.learning_centers_led.length > 0) {
      return _.find(this.learning_centers_led, lc => lc.organization_id === orgId);
    }

    return null;
  }

  canCloneActivity() {
    return this.highestRole === 'admin' ||
      this.highestRole === 'teamLeader' ||
      this.highestRole === 'user_creator';
  }
}
